import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import service from "../services/service";
import { GiClothes } from "react-icons/gi";
import useCheckBagStatus from "./useCheckBagStatus";

const BagReachCustomerThirdScan = () => {
  const navigate = useNavigate();
  const [scannedBox, setScannedBox] = useState(false);
  const [userName, setUserName] = useState(null);
  // to fetch name of the user
  const [bag, setBag] = useState(sessionStorage.getItem("bag"));

  useCheckBagStatus(bag ? bag : null);

  useEffect(() => {
    if (bag) {
      const fetchUserName = async () => {
        const response = await service.fetchUserName(bag);

        if (response.status === 200) {
          setUserName(response?.user?.first_name);
        } else {
          alert("שק לא משוייך למשתמש זה");
          navigate("/");
        }
      };
      fetchUserName();
    }
  }, [bag]);

  const customerReceivedBag = async () => {
    try {
      const response = await service.customerReceivedBag(bag);
      if (response.status === 200) {
        setScannedBox(true);
        // navigate("/customerFirstScan");
      }
    } catch (error) {
      console.log(error, "error in customerReceivedBag");
    }
  };
  return (
    <>
      <div className="main">
        <>
          <div className="flex-column">
            <h2>{`
                  יופי!
                  ${userName}`}</h2>

            <h3 style={{ margin: "10px 0 5px 0" }}>{`שק מספר: ${bag}`}</h3>

            {!scannedBox ? (
              <>
                <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                  נא אשר/י את קבלת השק
                </h2>

                <div className="submit">
                  <button className="submit-bag" onClick={customerReceivedBag}>
                    מאשר
                  </button>
                </div>
              </>
            ) : (
              <>
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px",
                    direction: "rtl",
                  }}
                >
                  השק שייך לך אפשר להתחיל למלא
                  <span>
                    <GiClothes size={"40px"} />
                  </span>
                </h3>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default BagReachCustomerThirdScan;
