const calculateChecksum = (f2) => {
  let sumEvenPositions =
    parseInt(f2[1]) +
    parseInt(f2[3]) +
    parseInt(f2[5]) +
    parseInt(f2[7]) +
    parseInt(f2[9]) +
    parseInt(f2[11]);
  let sumOddPositions =
    parseInt(f2[0]) +
    parseInt(f2[2]) +
    parseInt(f2[4]) +
    parseInt(f2[6]) +
    parseInt(f2[8]) +
    parseInt(f2[10]);

  let total = sumEvenPositions * 2 + sumOddPositions;
  let roundedUpTotal = Math.ceil(total / 10) * 10;

  return roundedUpTotal - total;
};
export default calculateChecksum;
