import { useEffect, useState } from "react";
import calculateChecksum from "../tools/calculateChecksum";
import service from "../services/service";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdDeliveryDining } from "react-icons/md";
import useCheckBagStatus from "./useCheckBagStatus";

const PickUpBagFirstScan = () => {
  const [bagScanned, setBagScanned] = useState(false);
  // const [bagNumberIsValid, setBagNumberIsValid] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();

  const { bag } = useParams();

  // useEffect(() => {
  //   console.log(bag, "bag");
  //   let bagIdentifier = bag;
  //   bagIdentifier = bagIdentifier.replace(/[^0-9]/g, "");
  //   const bagProdId = bagIdentifier.substring(7, 19);
  //   const bagCheckSum = bagIdentifier.slice(-1);
  //   const numberValidity = calculateChecksum(bagProdId);

  //   if (numberValidity !== parseInt(bagCheckSum)) {
  //     alert("invalid bag number");
  //     navigate(`/`);
  //   } else {
  //     // checkStatus();
  //     setBagNumberIsValid(true);
  //     sessionStorage.setItem("bag", bag);
  //   }
  // }, []);
  // custom hook to check bag status
  useCheckBagStatus(bag ? bag : null);

  const updateBagStatus = async () => {
    setDisableButton(true);

    const response = await service.pickUpBag(bag);
    if (response.status === 400 || response.status === 500) {
      alert(response.message);
      navigate(`/`);
      return;
    }

    if (response.status === 200) {
      setBagScanned(true);
    }
  };

  return (
    <>
      <div className="main">
        <>
          <h2>תיק מספר :</h2>
          <h2 style={{ marginTop: "10px" }}>{bag}</h2>

          {bagScanned ? (
            <>
              <h2 className="with-icon">
                יצא ללקוח
                <span style={{ marginTop: "10px" }}>
                  <MdDeliveryDining size={"40px"} />
                </span>
              </h2>
            </>
          ) : (
            <>
              <div className="submit">
                <button
                  disabled={disableButton}
                  className="submit-bag"
                  onClick={updateBagStatus}
                >
                  אשר איסוף
                </button>
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default PickUpBagFirstScan;
