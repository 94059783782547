import { useState, useEffect } from "react";
import service from "../services/service";
import useCheckBagStatus from "./useCheckBagStatus";
import { BsFillRocketTakeoffFill } from "react-icons/bs";

const PickUpBagAfterCustomerFinishedFifthScan = () => {
  const [bagScanned, setBagScanned] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bag, setBag] = useState(sessionStorage.getItem("bag"));
  const [name, setName] = useState(null);

  useCheckBagStatus(bag ? bag : null);

  useEffect(() => {
    const fetchUserName = async () => {
      const response = await service.fetchUserName(bag);
      if (response.status === 200) {
        setName(response?.user?.first_name);
      }
    };
    fetchUserName();
  }, [bag]);
  const pickUpBagAfterCustomerFinishedFifthScan = async () => {
    setDisableButton(true);
    const response = await service.pickUpBagAfterCustomerFinishedFifthScan(bag);
    if (response?.status === 200) {
      setBagScanned(true);
    }
  };
  return (
    <>
      <div className="main">
        <h2>
          <span>שק מספר :</span>
          <span>{bag}</span>
          <span>נאסף מ - {name}</span>
        </h2>
        {bagScanned ? (
          <>
            <h2 className="with-icon">
              תודה
              <span style={{ marginTop: "10px" }}>
                <BsFillRocketTakeoffFill size={"40px"} />
              </span>
            </h2>
          </>
        ) : (
          <>
            <div className="submit">
              <button
                disabled={disableButton}
                className="submit-bag"
                onClick={pickUpBagAfterCustomerFinishedFifthScan}
              >
                אשר איסוף
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PickUpBagAfterCustomerFinishedFifthScan;
