import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import PickUpBagFirstScan from "./Components/pickUpBagFirstScan";
import AssignBag from "./Components/assignBagToUser";
import DeliveryArrivalSecondScan from "./Components/deliveryArrivalSecondScan";
import BagReachCustomerThirdScan from "./Components/bagReachCustomerThirdScan";
import CustomerFinishedFillingBagForthScan from "./Components/customerFinishedFillingBagForthScan";
import PickUpBagAfterCustomerFinishedFifthScan from "./Components/pickUpBagAfterCustomerFinishedFifthScan";
import SortingCenterSixScan from "./Components/sortingCenterSixScan";
import Homepage from "./Components/homePage";
function App() {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/assignBag" element={<AssignBag />} />
        <Route path="/pickupBag/:bag" element={<PickUpBagFirstScan />} />
        <Route
          path="/DeliveryArrivalSecondScan"
          element={<DeliveryArrivalSecondScan />}
        />
        <Route
          path="/bagReachCustomerThirdScan"
          element={<BagReachCustomerThirdScan />}
        />
        <Route
          path="/customerFinishedFillingBagForthScan"
          element={<CustomerFinishedFillingBagForthScan />}
        />
        <Route
          path="/pickUpBagAfterCustomerFinishedFifthScan"
          element={<PickUpBagAfterCustomerFinishedFifthScan />}
        />
        <Route path="/sortingCenter" element={<SortingCenterSixScan />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
