import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import calculateChecksum from "../tools/calculateChecksum";

import service from "../services/service";
const useCheckBagStatus = (bag) => {
  const navigate = useNavigate();
  const [scanStatus, setScanStatus] = useState(null);
  const [bagStatus, setBagStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const checkStatus = async () => {
      const response = await service.checkBagStatus(bag);
      if (response.status === 200) {
        setScanStatus(response.scanStatus);
        setBagStatus(response.bagStatus);
        setUserId(response.userId);
        // The hook is called here, within a React component.
      } else if (response.status === 400) {
        // if (!beforeFirstScan) {
        navigate(`/`);
        // }
      }
    };

    // if user came from assign bag do nothing

    if (bag) {
      let bagIdentifier = bag;
      bagIdentifier = bagIdentifier.replace(/[^0-9]/g, "");
      const bagProdId = bagIdentifier.substring(7, 19);
      const bagCheckSum = bagIdentifier.slice(-1);
      const numberValidity = calculateChecksum(bagProdId);

      if (numberValidity !== parseInt(bagCheckSum)) {
        alert("invalid bag number");
        navigate(`/`);
      } else {
        checkStatus();
        sessionStorage.setItem("bag", bag);
      }
    } else {
      navigate(`/`);
    }
    if (scanStatus === null) return;
    switch (scanStatus) {
      case 0:
        if (bagStatus === "bag assigned to user") {
          navigate(`/pickupBag/${bag}`);
        }
        break;

      case 1:
        navigate(`/DeliveryArrivalSecondScan`);
        break;
      case 2:
        navigate(`/bagReachCustomerThirdScan`);
        break;
      case 3:
        navigate(`/customerFinishedFillingBagForthScan`);
        break;
      case 4:
        navigate(`/pickUpBagAfterCustomerFinishedFifthScan`);
        break;

      case 5:
        navigate("/sortingCenter");
        break;

      case 6:
        navigate("/");
        break;

      default:
        navigate(`/`);
        break;
    }
  }, [scanStatus, bagStatus, userId, bag, navigate]);
};

export default useCheckBagStatus;
