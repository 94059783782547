import { useState, useEffect } from "react";
import service from "../services/service";
import useCheckBagStatus from "./useCheckBagStatus";
import { GiRecycle } from "react-icons/gi";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const SortingCenterSixScan = () => {
  const [bagScanned, setBagScanned] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bag, setBag] = useState(sessionStorage.getItem("bag"));
  const navigate = useNavigate();

  useCheckBagStatus(bag ? bag : null);

  const sortingCenterSixScan = async () => {
    try {
      if (!bag) return navigate("/");
      setDisableButton(true);
      const response = await service.sortingCenterSixScan(bag);
      if (response?.status === 200) {
        sessionStorage.removeItem("bag");
        setBagScanned(true);
      }
    } catch (error) {
      console.log(error, "error in sortingCenterSixScan");
    }
  };
  return (
    <>
      <div className="main">
        <h2>
          שק במרכז מיון
          <span>
            <GiRecycle />
          </span>
        </h2>
        {bagScanned ? (
          <>
            <h2>השק שויך למרכז המיון תודה</h2>
          </>
        ) : (
          <>
            <div className="submit">
              <button
                disabled={disableButton}
                className="submit-bag"
                onClick={sortingCenterSixScan}
              >
                מאשר{" "}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SortingCenterSixScan;
