const Homepage = () => {
  return (
    <>
      <div className="main">
        <h2>סרוק את השק בבקשה</h2>
      </div>
    </>
  );
};

export default Homepage;
