import Select from "react-select";
import { useState, useEffect } from "react";
import service from "../services/service";
import "./styles/assignBagToUser.css";
import { useNavigate } from "react-router-dom";
import useCheckBagStatus from "./useCheckBagStatus";

const AssignBag = () => {
  const [users, setUsers] = useState([]);
  const [bags, setBags] = useState([]);
  const [error, setError] = useState(null);
  const [selectedBag, setSelectedBag] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  // pass true only in the first screen

  // const bag = sessionStorage.getItem("bag");
  // useCheckBagStatus(bag ? bag : null);

  useEffect(() => {
    const fetchAvailableBagsAndUsers = async () => {
      const response = await service.fetchAvailableBags();
      if (response.status === 200) {
        setBags(response.bags);
        setUsers(response.users);
      } else {
        alert("No Bags Available");
      }
    };
    fetchAvailableBagsAndUsers();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "50vw",
      borderRadius: "4px",
      border: "2px solid #3C4964", // Replace --color-name with your color variable
      marginBottom: "10px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
  };

  const mergeBags = async () => {
    setDisableButton(true);

    setError(null);
    if (selectedBag === null || selectedUser === null) {
      setError("Please select a bag and a user");
      setDisableButton(false);
      return;
    }

    try {
      const data = {
        bagIdentifier: selectedBag.label,
        bagId: selectedBag.value,
        userId: selectedUser.value,
      };

      const response = await service.assignBagToUser(data);
      if (response.status === 200) {
        navigate(`/pickupBag/${selectedBag.label}`);
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.log(error, "error in mergeBags");
    }
  };
  return (
    <>
      <div className="main">
        <h1>שייך תיק למשתמש</h1>

        <div className="select-wrapper">
          <div className="select-container">
            {bags?.length && (
              <>
                <div className="title">בחר תיק</div>

                <Select
                  styles={customStyles}
                  placeholder="בחר תיק"
                  options={bags.map((bag) => {
                    return { value: bag.id, label: bag.bag_identifier };
                  })}
                  name="bag"
                  onChange={(e) => {
                    setError(null);
                    setSelectedBag(e);
                  }}
                />
              </>
            )}
          </div>

          <div className="select-container">
            {users?.length && (
              <>
                <div className="title">בחר משתמש</div>
                <Select
                  name="user"
                  placeholder="בחר משתמש"
                  styles={customStyles}
                  options={users.map((user) => {
                    return { value: user.id, label: user.first_name };
                  })}
                  onChange={(e) => {
                    setError(null);
                    setSelectedUser(e);
                  }}
                />
              </>
            )}
          </div>
        </div>

        {error && <div className="error">{error}</div>}
        <div className="submit">
          <button
            onClick={mergeBags}
            className={
              (selectedBag && selectedUser) || disableButton
                ? "submit-bag"
                : "dont-submit"
            }
          >
            בצע
          </button>
        </div>
      </div>
    </>
  );
};

export default AssignBag;
