import baseUrl from "../axios/baseUrl";

const service = {
  checkBagStatus: async (bagNumber) => {
    try {
      const { data } = await baseUrl.get(`/bags/status/${bagNumber}`);
      return data;
    } catch (error) {
      console.log(error, "error in checkBagStatus");
    }
  },
  fetchAvailableBags: async () => {
    try {
      const { data } = await baseUrl.get("/bags/all");
      return data;
    } catch (error) {
      console.log(error, "error in fetchAvailableBags");
    }
  },
  assignBagToUser: async (bagData) => {
    try {
      const { data } = await baseUrl.post("/bags/assign", {
        bagData,
      });
      return data;
    } catch (error) {
      console.log(error, "error in assignBagToUser");
    }
  },
  pickUpBag: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/pickup", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in firstScanPickUpBag");
    }
  },
  deliveryArrivalSecondScan: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/deliveryArrival", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in deliveryArrivalSecondStation");
    }
  },
  fetchUserName: async (bagNumber) => {
    try {
      const { data } = await baseUrl.get(`/bags/userName/${bagNumber}`);
      return data;
    } catch (error) {
      console.log(error, "error in fetchUserName");
    }
  },
  customerReceivedBag: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/customerReceived", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in customerReceivedBag");
    }
  },
  customerFinishedFillingBag: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/customerFinishedFilling", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in customerFinishedFillingBag");
    }
  },
  pickUpBagAfterCustomerFinishedFifthScan: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/pickupAfterCustomerFinished", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in pickUpBagAfterCustomerFinishedFifthScan");
    }
  },
  sortingCenterSixScan: async (bagNumber) => {
    try {
      const { data } = await baseUrl.post("/bags/sortingCenterSixScan", {
        bagNumber,
      });
      return data;
    } catch (error) {
      console.log(error, "error in sortingCenterSixScan");
    }
  },
};

export default service;
