import { useEffect, useState } from "react";
import service from "../services/service";
import { IoIosHome } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useCheckBagStatus from "./useCheckBagStatus";

const DeliveryArrivalSecondScan = () => {
  const [bagScanned, setBagScanned] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bag, setBag] = useState(sessionStorage.getItem("bag"));
  const navigate = useNavigate();

  useCheckBagStatus(bag ? bag : null);

  const bagStatusUpdate = async () => {
    if (!bag) return navigate("/");

    setDisableButton(true);

    const response = await service.deliveryArrivalSecondScan(bag);
    if (response?.status === 200) {
      setBagScanned(true);
    }
  };
  return (
    <>
      <div className="main">
        {!bagScanned ? (
          <>
            <h2 className="with-icon">
              שק הגיע ל{" "}
              <span style={{ marginTop: "10px" }}>
                <IoIosHome />
              </span>{" "}
              הלקוח ?
            </h2>
            <div className="submit">
              <button
                disabled={disableButton}
                className="submit-bag"
                onClick={bagStatusUpdate}
              >
                מאשר
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>סטטוס עודכן שק בבית הלקוח</h2>
          </>
        )}
      </div>
    </>
  );
};

export default DeliveryArrivalSecondScan;
