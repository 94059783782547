import { useState, useEffect } from "react";
import { IoBagOutline } from "react-icons/io5";
import { IoBagCheckSharp } from "react-icons/io5";
import service from "../services/service";
import useCheckBagStatus from "./useCheckBagStatus";
import { useNavigate } from "react-router-dom";
const CustomerFinishedFillingBagForthScan = () => {
  const [bagScanned, setBagScanned] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bag, setBag] = useState(sessionStorage.getItem("bag"));
  useCheckBagStatus(bag ? bag : null);
  const navigate = useNavigate();
  const customerFinishedFillingBag = async () => {
    if (!bag) return navigate("/");

    setDisableButton(true);
    const response = await service.customerFinishedFillingBag(bag);
    if (response.status === 200) {
      setBagScanned(true);
    }
  };
  return (
    <>
      <div className="main">
        {bagScanned ? (
          <>
            <h2>
              <span style={{ marginTop: "10px" }}>
                <IoBagCheckSharp size={"40px"} />
              </span>
              <span>תודה!</span>
              <span>אנחנו נאסוף את השק שלך בקרוב.</span>
              <span> נתעדכן ב-WhatsApp!</span>
            </h2>
          </>
        ) : (
          <>
            <h2
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   textAlign: "center",
            // }}
            >
              סיימת למלא את השק ?
              <span style={{ marginTop: "10px" }}>
                <IoBagOutline size={"40px"} />
              </span>
            </h2>
            <div className="submit">
              <button
                disabled={disableButton}
                className="submit-bag"
                onClick={customerFinishedFillingBag}
              >
                אשר איסוף
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerFinishedFillingBagForthScan;
